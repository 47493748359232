// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
// import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import Link from '../components/link'
// import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Preface',
  slug: '/preface',
  abstract: about.description,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Preface', slug: '/preface' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <h1>Preface</h1>
    <p className="dropcap">
      <span>M</span>irra Alfassa, known as the Mother of the Sri Aurobindo
      Ashram, was Sri Aurobindo’s partner and counterpart in every way. They had
      the same spiritual realisations and they worked for the same goal. Over
      one hundred years ago, they experienced the New Consciousness, which is
      now recognized everywhere as the New Consciousness that is manifesting to
      change mankind, and they devoted their lives to bring it to the world.
    </p>
    <p className="text-indent">
      Mother was born in Paris, France on February 21, 1878. She was conscious
      from her birth. At the age of five, she knew that she would do a great
      work for the world. By then she had repeated experiences of the white
      Light and the active Consciousness which was just beginning to be
      available for man.
    </p>
    <p className="text-indent">
      Starting from the time Mother was eleven years of age, she was working in
      the subtle planes to help mankind. Various spiritual masters came to teach
      her at night. Sri Aurobindo was one of her teachers. She knew then that
      they would meet and work together. She came to Pondicherry to meet Sri
      Aurobindo on March 24, 1914, and immediately recognized him as her early
      teacher and the partner of her life and her life’s work. She returned to
      live permanently with Sri Aurobindo on April 24, 1920.
    </p>
    <p className="text-indent">
      Mother lived quietly, concentrating on her inner spiritual work until
      November 26, 1926, when she came forward to create and manage the Sri
      Aurobindo Ashram. From that time she was known as the Mother.
    </p>
    <p className="text-indent">
      In November, 1926, Sri Aurobindo attained the mastery of the highest level
      of mental consciousness, which he called the ‘Overmind’. In the course of
      human evolution, man had reached the development of the mind, and those
      people who were the most evolved had the possibility of receiving Overmind
      thoughts from the highest mental level of the creation. Sri Aurobindo
      called the next level above the Overmind the ‘Supermind’, because it did
      not involve the mind or its processes. He explained that it is the next
      evolutionary stage of humanity, now that man has evolved into a mental
      being. Once he had the Overmind consciousness, he retired to his room to
      devote himself to attaining the Supramental consciousness and to helping
      mankind to receive it.
    </p>
    <p className="text-indent">
      Mother’s establishment of the Ashram and her daily work to help the early
      Ashramites receive the New Consciousness was the first practical effort in
      the physical world to help mankind take this new evolutionary step. Her
      tireless love and care inspired the people around her to write many
      adoring books about her and about everything that she never stopped doing
      for them and within them. We can know something about her from these
      books, written by others. She did not have the time to write books, but
      she did answer letters, and she taught classes, which were transcribed and
      published.
    </p>
    <p className="text-indent">
      We have eighteen volumes of Mother’s Collected Works. Her teachings bring
      us her work and Sri Aurobindo’s work and teachings in a simple and easily
      understandable form. They give a realistic and practical view of Sri
      Aurobindo’s philosophy and the practice of his yoga. They are of
      immeasurable help to any spiritual seeker.
    </p>
    <p className="text-indent">
      Because of the Second World War, in the early 1940’s, families of Sri
      Aurobindo’s followers with young children took refuge in the Ashram. Many
      children refused to leave. Because of Sri Aurobindo’s and Mother’s work,
      and the yogic efforts of the early Ashramites, a foundation of the new
      consciousness had been established which could support the presence of
      children in the Ashram. Mother and Sri Aurobindo had the same view of
      education as a means to bring out the best of a person’s potential and
      foster its development. Today their ideas of child development and
      education are being used everywhere, whether or not people know that they
      were first taught by Sri Aurobindo and Mother over a hundred years ago.
    </p>
    <p className="text-indent">
      In 1943, Mother started the Ashram School, which became The Sri Aurobindo
      International Center of Education. Every child had her individual
      attention and care. She worked with them and within them in the same way
      that she worked with the other Ashram residents and seekers. Sri
      Aurobindo’s and Mother’s teachings were put into practice in the daily
      life of these growing children. The yogic ideas of working for personal
      progress and receiving the new consciousness on every level of the being
      were extended to children’s education in a living way.
    </p>
    <p className="text-indent">
      Soon Mother was teaching French in the school along with her other work of
      organizing and overseeing every aspect of Ashram life, from meals to
      finance, to building construction, to medical matters as well as her
      constant inner and outer spiritual work with Ashramites, students, and
      visitors.
    </p>
    <p className="text-indent">
      In 1950, Mother began to teach a class in French about Sri Aurobindo’s
      yoga and philosophy to one group of children. Everybody wanted to attend
      Mother’s class, which was held in the school playground on Wednesday
      evenings. Soon almost everyone had permission to attend Mother’s Wednesday
      class, but only the students and their teachers were allowed to ask
      questions or to speak. These talks went on through 1958, and are contained
      in the volumes, titled “Mother’s Questions and Answers”.
    </p>
    <p className="text-indent">
      In these classes, Mother teaches the Ashram school children the highest,
      deepest, most complex spiritual and yogic philosophy and practice in a way
      that is easy for everyone to grasp. Over the years she often spoke on
      subjects she had already taught. Each time she brought new and deeper
      understanding of different aspects of Sri Aurobindo’s Yoga and philosophy,
      or the nature of humanity and the world, or the New Consciousness.
    </p>
    <p className="text-indent">
      Mother was a true Guru, and a Universal Guru in every sense of the word.
      Her teaching has something for everyone because she taught for the purpose
      of human progress on all levels and in all parts of the human being. The
      clarity and simplicity of her expression communicates something to
      everyone who reads her books. She was working in everyone who came to her,
      whether to see her physically, or with their inner consciousness. Her
      consciousness was also active on the universal subtle planes of creation,
      and she worked to enlighten thought and life impulse on a universal scale
      for all of mankind.
    </p>
    <p className="text-indent">
      The volumes of Mother’s Questions and Answers include talks given to
      Ashramites in 1929-1931, and her classes to the students from 1950 to
      1958. However, the present series of Auroville Radio Talks on Mother’s
      Questions and Answers begins on the date of July 12, 1955. The date was
      chosen because it is the first date of an available sound recording of
      Mother teaching the class in French. The original recording of Mother’s
      French class is part of the Radio Talk. It can be heard at the end of each
      talk.
    </p>
    <p className="text-indent">
      Each class is illustrated by one of the flowers which Mother named for the
      purpose of increasing our consciousness. She saw that every flower
      radiated some aspect of the universal movement towards higher
      consciousness, and she gave the meaning of over 900 flowers.
    </p>
    <p className="text-indent">
      Although Mother taught in French, gifts of the spirit come from her
      consciousness in the very sound of her voice. Her great love for all
      resounds in her voice. It is good to listen to her whether or not the
      hearer understands the meaning of the words, because every contact with
      Mother brings consciousness and love.
    </p>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
